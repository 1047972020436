<template>
  <div>
    <div class="user-card" style="margin-bottom: 20px;">
      <span style="margin-right: 10px;">为</span>
      <img v-if="user.headImg" :src="user.headImg" alt="" />
      <p>{{user.userName}}<span v-if="user.userPhone">({{uesr.userPhone}})</span><span style="margin-left: 10px;">{{({'moeny': '充值', 'voucher': '构建充值卡'})[type]}}</span></p>
    </div>
    <a-form-model
      layout="horizontal"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      :model="model"
      ref="form"
      :colon="false"
      @submit.native.prevent
      @submit="submit"
    >
      <template v-if="type === 'money'">
        <a-form-model-item label="充值金额" prop="amount" :rules="[
          { required: true, message: '请输入' },
          { pattern: /^[0-9]+(\.[0-9]{1,2})?$/, message: '格式不正确' }
        ]">
          <a-input v-model="model.amount" placeholder="请输入充值金额"></a-input>
        </a-form-model-item>
      </template>

      <template v-else-if="type === 'voucher'">
        <a-form-model-item label="充值卡规格" prop="productSku" :rules="[
          { required: true, message: '请选择充值卡及规格' }
        ]">
          <sku-select v-model="model.productSku" />
        </a-form-model-item>
        <a-form-model-item label="数量" prop="num" :rules="[
          { required: true, message: '请输入' },
          { pattern: /^[1-9][0-9]*$/, message: '格式不正确' }
        ]">
          <a-input v-model="model.num" placeholder="请输入数量"></a-input>
        </a-form-model-item>
        <a-form-model-item label="充值说明" prop="buyNote">
          <a-input v-model="model.buyNote" placeholder="请输入说明"></a-input>
        </a-form-model-item>
      </template>
      <a-form-model-item label=" ">
        <a-button type="primary" :loading="loading" @click="submit">提交</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import { includeFields, wrapAwait } from '@/utils/tools'
import { capital } from '@/api'
import { message } from 'ant-design-vue'
import SkuSelect from './SkuSelect'

export default {
  name: 'ChargeForm',
  components: {
    SkuSelect,
  },
  props: {
    type: String, // money voucher
    user: Object,
  },
  setup (props, { emit }) {
    const model = reactive({
      userId: props.user.userId,
      shopId: getSession(SHOPID),
      amount: '',
      productSku: [],
      num: '',
      buyNote: ''
    })

    const loading = ref(false)
    const form = ref(null)
    async function submit () {
      const valid = await form.value.validate()
      if (!valid) return
      loading.value = true
      const [err] = await wrapAwait(
        props.type === 'money'
          ? capital.chargeMoney(includeFields(model, ['userId', 'shopId', 'amount']))
          : capital.chargeVoucher({
            ...includeFields(model,
              ['userId', 'shopId', 'productId', 'num', 'buyNote']
            ),
            productId: model.productSku[0],
            productSkuId: model.productSku[1]
          })
      )
      loading.value = false
      if (err) {
        message.error(err.message)
      } else {
        message.success('提交成功')
        emit('close')
        emit('success')
      }
    }

    return {
      model,
      submit,
      loading,
      form,
    }
  }
}
</script>

<style>

</style>
